.container.contact__container {
    width: 70%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
        background: whitesmoke;
        padding: 1rem;
        border-radius: 1.2rem;
        text-align: center;
        border: 1px solid transparent;
        transition: var(--transition);
        cursor: pointer;
        width: 250px;
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-priamry-variant);
}

.contact__option-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    position: relative;
    left: 45%;
    transform: translate(-50% -50%);
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
}


form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 2px solid var(--color-priamry-variant);
    resize: none;
    color: var(---color-white);
}

@Media screen and (max-width:1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
        flex: content;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-ms);
    }
}