@import url('https://fonts.googleapis.com/css2?family=Questrial:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        font-family: Questrial;
    }
}